<script setup lang="ts">
import Button from '~/components/molecules/Button.vue'
import { ButtonType } from '~/src/enums/Button'
import { useSignOut } from '~/composables/useSignOut'

const { signOut } = useSignOut()
</script>

<template>
  <Button class="!absolute top-10 right-12" :type="ButtonType.Link" @click="signOut">
    Exit
  </Button>
  <div class="sm:px-12 px-6 py-28 mx-auto w-full m-auto">
    <slot />
  </div>
</template>
<style lang="postcss" scoped>

</style>
